import axios from "axios";
import VueAxios from "vue-axios";
import Vue from "vue";

const tokens = JSON.parse(localStorage.getItem("user.tokens"));
if (tokens) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${tokens.access_token}`;
}
const api = axios.create({
  baseURL: process.env.VUE_APP_API
});
Vue.use(VueAxios, api);
