<template>
  <button class="button-chat" @click="$emit('click')"></button>
</template>

<script>
export default {
  name: "AddChatButton"
};
</script>

<style lang="scss" scoped>
#maitrea-app-widget-container {
  .button-chat {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    background: #ffffff;
    border-radius: 50px;
    position: fixed;
    right: 32px;
    bottom: 32px;
    width: 56px;
    height: 56px;
    appearance: none;
    border: none;
    cursor: pointer;
    background: #ffffff url("/../../img/plus.svg") 50% 50% no-repeat;
  }
}
</style>
