<template>
  <div v-if="mediaList.length" class="message-media-list-wrapper">
    <ul class="images-list">
      <li v-for="(item, index) in mediaList" :key="index + 'image'">
        <span class="media-wrapper">
          <img
            v-if="!item.isVideo"
            :src="item.url ? item.url : loader"
            :alt="index + 'Uploaded image'"
          />
          <video v-if="item.isVideo">
            <source :src="item.url ? item.url : loader" type="video/mp4" />
          </video>
        </span>
        <span class="filename">{{ item.file.name }}</span>
        <span class="remove-icon" @click="onRemoveFile(index)"></span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "MessageFiles",
  props: {
    mediaList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loader: "/../../img/loader.svg"
    };
  },
  methods: {
    onRemoveFile(index) {
      this.$emit("delete", index);
    }
  }
};
</script>

<style lang="scss" scoped>
#maitrea-app-widget-container {
  .message-media-list-wrapper {
    .images-list {
      margin: 0px;
      padding: 0px;
      display: block;
      max-width: 100%;
      li {
        white-space: nowrap;
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        position: relative;

        .media-wrapper {
          width: 50px;
          height: 50px;
          display: flex;
          margin-right: 5px;
          img,
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .filename {
          display: inline-block;
          width: calc(100% - 110px);
          white-space: nowrap;
          font-size: 14px;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .remove-icon {
          position: absolute;
          width: 18px;
          height: 18px;
          background: transparent url("/../../img/close-user-list.svg") 50% 50%
            no-repeat;
          top: 50%;
          transform: translateY(-50%);
          right: 0px;
          cursor: pointer;
          z-index: 999;
          background-size: cover;
        }
      }
    }
  }
}
</style>
