import axios from "@/plugins/axios";

const namespaced = true;
import Vue from "vue";
const mutations = {
  /**
   * Setup user for store
   * @param state
   * @param payload{Object<{name: String, language: String, email: String, photo: String, external_id:Number}>}
   */
  setUser(state, payload) {
    state.user = payload;
    localStorage.setItem("user.profile", JSON.stringify(state.user));
  },
  /**
   * Set tokens for use
   * @param state
   * @param payload{Object<{access_token: String, refresh_token: String, token_type: String=bearer}>}
   */
  setTokens(state, payload) {
    state.tokens = payload;
    localStorage.setItem("user.tokens", JSON.stringify(state.tokens));
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${state.tokens.access_token}`;
  }
};

const state = {
  user: null,
  tokens: null
};

const actions = {
  /**
   * @param context {Object<{email:String, password:String}>}
   * @param payload
   * @returns {Promise<AxiosResponse<{"access_token": String, "refresh_token": String, "token_type": String}>>}
   */
  async login(context, payload) {
    return await Vue.axios.post("/auth/login", payload);
  },
  /**
   * Login with external data
   * @param context {Object<{refresh_token: String}>}
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  async loginWithExternal(context, payload) {
    return await Vue.axios.get(
      `/auth/external_login?refresh_token=${payload.refresh_token}`
    );
  },

  /**
   * @param context
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  async profile(context, payload) {
    return await Vue.axios.get("/users/profile");
  },
  /**
   * @param context
   * @param payload {Object<{search: String, show_from: Number, limit: Number}>}
   * @returns {Promise<void>}
   */
  async filter(context, payload) {
    let query = new URLSearchParams(payload).toString();
    return await Vue.axios.get(`/users/filter?${query}`);
  },
  /**
   * @param context
   * @param payload {Object<{show_from: Number, limit: Number}>}
   * @returns {Promise<AxiosResponse<any>>}
   */
  async friends(context, payload) {
    let query = new URLSearchParams(payload).toString();
    return await Vue.axios.get(`/users/friends?${query}`);
  },
  async externalProfile(context, payload) {
    return await Vue.axios.get(`/users/external`);
  },
  async refreshTokens(state, payload) {
    return await Vue.axios.post(`/auth/refresh`);
  }
};

export default {
  namespaced,
  actions,
  mutations,
  state
};
