<template>
  <div class="message-wrapper" :class="{ 'position-right-wrapper': isRight }">
    <div class="message-item" :class="{ 'custom-width': !hasMedia }">
      <div class="message-block">
        <div v-if="!isRight" class="image-side">
          <slot name="avatar"></slot>
        </div>
        <div class="message-side" :class="colors">
          <slot name="username"></slot>
          <div class="content" :class="{ 'has-media': hasMediaSlot }">
            <slot name="message"></slot>
            <slot name="media"></slot>
            <slot name="date"></slot>
            <slot name="checkers"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageItem",
  props: {
    userType: {
      type: String,
      default: "user",
      validator(value) {
        return ["user", "me"].includes(value);
      }
    },
    order: {
      type: String,
      default: "right"
    },
    massMessage: {
      type: Array,
      required: false,
      default: () => []
    },
    hasMedia: {
      type: [Boolean, Number],
      default: 0
    }
  },
  computed: {
    hasMediaSlot() {
      return this.$slots["media"];
    },
    isRight() {
      return this.userType !== "user" && this.order !== "left";
    },
    colors() {
      return {
        [`message-side__${this.userType}`]: true,
        [`message-side__${this.order}`]: true
      };
    }
  }
};
</script>

<style lang="scss" scoped>
#maitrea-app-widget-container {
  .message-wrapper {
    display: flex;
    width: 100%;

    .message-item {
      max-width: 446px;
      width: 100%;
      margin-bottom: 15px;

      .message-block {
        .image-side {
          margin-right: 9px;
          display: flex;
          align-items: flex-start;

          .svg {
            display: flex;
            align-items: flex-end;
            width: 40px;
            height: 40px;
            border-radius: 50%;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
        }

        .position-right-image {
          order: 1;
          margin-right: 0;
          margin-left: 16px;
        }

        .message-side {
          position: relative;
          padding: 9px 12px;
          background: white;
          box-shadow: 0 0 4px rgba(246, 128, 89, 0.15);
          border-radius: 0 10px 10px 10px;

          @media (max-width: 900px) {
            max-width: 250px;
          }

          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            bottom: 0;
          }

          .username {
            margin-top: 0;
            margin-bottom: 9px;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 13px;
            color: #7c42ff;
          }

          .message-slider {
            margin-bottom: 10px;
          }

          &__me {
            border-radius: 10px 0 10px 10px;
            background: #ffe4c9;
          }
          .content {
            -ms-word-break: break-all;
            word-break: break-all;
            word-break: break-word;

            .content-item {
              display: inline-block;
            }
            .message {
              width: 95%;
              margin: 0;
              font-size: 17px;
              line-height: 20px;
              color: #162521;
            }
            .date {
              font-size: 12px;
              line-height: 12px;
              color: #979797;
              margin-left: 12px;
              display: block;
              text-align: right;
            }
            .checkers {
              position: absolute;
              right: 5px;
              top: 5px;
              background: transparent url("/../img/checks.svg") 50% 50%
                no-repeat;
              display: block;
              width: 19px;
              height: 10px;
              z-index: 10;
              filter: grayscale(1);
              &.is-read {
                filter: none;
              }
            }
          }

          .has-media {
            display: block;
            position: relative;

            .date {
              position: absolute;
              bottom: 13px;
              right: 9px;
              color: #fff;
            }
            video {
              width: 100%;
            }
          }

          &__left {
            &::before {
              border-left: 8px solid transparent;
              left: -7px;
            }
          }
          &__right {
            &::before {
              border-right: 8px solid transparent;
              right: -7px;
            }
          }
        }
      }

      .position-right-date {
        justify-content: flex-start;
      }
    }

    .custom-width {
      max-width: fit-content !important;
    }
  }

  .position-right-wrapper {
    justify-content: flex-end;
  }
}
</style>
