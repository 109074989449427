import Vue from "vue";
import Vuex from "vuex";
import users from "@/store/users/index";
import media from "@/store/media/index";
import messages from "@/store/messages/index";
import chats from "@/store/chats/index";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    users,
    chats,
    media,
    messages
  }
});
