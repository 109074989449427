import Vue from "vue";

const AuthPlugin = {};

AuthPlugin.install = function(Vue) {
  Vue.prototype.$auth = function() {
    const store = this.$store;
    return {
      loggedIn: () => {
        return store.state.users.user !== null;
      },
      user: () => {
        if (store.state.users.user) {
          return store.state.users.user;
        }
        return this._user;
      },
      tokens: () => {
        return JSON.parse(localStorage.getItem("user.tokens"));
      },
      _user: () => {
        const user = localStorage.getItem("user.profile");
        return JSON.parse(user);
      },
      async fetchUser() {
        const user = await store.dispatch("users/profile");
        await store.commit("users/setUser", user.data);
      },
      async refreshTokens() {
        const tokens = JSON.parse(localStorage.getItem("user.tokens"));
        if (!tokens) {
          return;
        }
        Vue.axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${tokens.refresh_token}`;
        const response = await store.dispatch("users/refreshTokens");
        await store.commit("users/setTokens", response.data);
      }
    };
  };
  // Vue.mixin({
  //   created: async function() {
  //     await this.$auth().refreshTokens();
  //   }
  // });
};

export default AuthPlugin;
