<template>
  <div
    v-if="username.length > 0"
    class="user-lists"
    :class="{
      'float-list': float,
      scrollbar: filteredUsers.length > 3 && !loading
    }"
  >
    <div v-if="!loading">
      <ul v-if="filteredUsers.length">
        <li
          v-for="user in filteredUsers"
          :key="user.id"
          @click="toLink(user.id)"
        >
          <span class="user-avatar">
            <img class="round-avatar-image" :src="user.avatar" alt="avatar" />
          </span>
          <span class="username">{{ user.name }}</span>
        </li>
      </ul>
      <p v-else-if="username.length > 0" class="no-user">Not Found</p>
    </div>
    <div v-if="loading" class="loading-holder">
      <img src="/img/loader.svg" alt="Loader" />
    </div>
  </div>
</template>

<script>
export default {
  name: "UserList",
  props: {
    username: {
      type: String,
      required: false,
      default: ""
    },
    float: {
      type: Boolean,
      required: false,
      default: false
    },
    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      filteredUsers: [],
      users: [],
      searchTimeOut: undefined,
      searchTimeOutTime: 1000,
      loading: false
    };
  },
  watch: {
    async username(val) {
      this.loading = true;
      console.log(val, "On change user name");
      if (this.searchTimeOut || !val.length) {
        clearTimeout(this.searchTimeOut);
        this.filteredUsers = [];
      }
      this.searchTimeOut = setTimeout(async () => {
        this.loading = true;
        const searchResponse = await this.$store.dispatch("users/filter", {
          search: val,
          limit: 100
        });
        const response = searchResponse.data.users;
        this.filteredUsers = response.map(item => {
          return {
            avatar: item.photo,
            name: item.name,
            id: item.external_id
          };
        });
        this.loading = false;
      }, this.searchTimeOutTime);
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
  async created() {
    this.filteredUsers = [];
  },
  methods: {
    close(e) {
      const needClose =
        !e.target.classList.contains("plus-btn-image") &&
        !e.target.classList.contains("focus-visible") &&
        !this.$el.contains(e.target);
      if (needClose) {
        this.$emit("update:show", false);
      }
    },
    toLink(id) {
      this.$emit("open-chat", id);
    }
  }
};
</script>

<style lang="scss" scoped>
#maitrea-app-widget-container {
  .loading-holder {
    margin: 0 auto;
    text-align: center;
  }

  .user-lists {
    background: #ffffff;
    box-shadow: 0 1px 8px #e4e1d8;
    border-radius: 10px;
    position: absolute;
    top: 96px;
    left: 84px;
    padding: 12px;
    width: 378px;
    z-index: 1;

    ul {
      padding-left: 0;

      li {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          background: rgba(255, 102, 70, 0.2);
          border-radius: 10px;
        }

        .username {
          margin-left: 10px;
        }

        .user-avatar {
          width: 60px;
          height: 60px;
          overflow: hidden;
          display: flex;
          border-radius: 50%;
          flex: 0 0 auto;

          .round-avatar-image {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .no-user {
      height: 25px;
    }
  }

  .scrollbar {
    height: 270px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 8px;
    }
  }

  .float-list {
    left: 16px;
    width: 254px;
  }
}
</style>
