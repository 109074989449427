import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
Vue.prototype.$store = store;
App.store = store;
import AuthPlugin from "./plugins/auth";
Vue.use(AuthPlugin);
import vueCustomElement from "vue-custom-element";
import "document-register-element/build/document-register-element";
import "./plugins/axios";
import moment from "vue-moment";

Vue.use(moment);
Vue.use(vueCustomElement);

App.router = router;
Vue.customElement("chat-widget", App, {
  connectedCallback() {
    console.info("connectedCallback", this);
  }
});
