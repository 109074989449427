<template>
  <div>
    <LoginForm v-if="!user" />
    <div v-if="user" id="app">
      <div id="maitrea-app-widget-container">
        <AddChatButton @click="showChat" />
        <FirstFloatChat
          v-for="(chat, index) in chats"
          :key="chat.id"
          ref="component-index-{{chat.id}}"
          :token="token"
          :position="index"
          :chat-id="chat.id"
          :user="user"
          :is-expanded.sync="isExpanded"
          :is-online="getIsOnline(chat.id)"
          @expand="expand"
          @close="close"
        ></FirstFloatChat>
      </div>
    </div>
  </div>
</template>

<script>
import AddChatButton from "@/components/buttons/AddChatButton";
import FirstFloatChat from "@/components/float-chats/FirstFloatChat";
import LoginForm from "@/components/Login";
import sse from "@/mixins/sse";

export default {
  components: {
    AddChatButton,
    FirstFloatChat,
    LoginForm
  },
  mixins: [sse],
  props: {
    token: {
      default: "",
      required: false,
      type: String
    }
  },
  data() {
    return {
      chats: [],
      showListChat: false,
      path: "",
      allowTestLogin: false,
      isExpanded: false,
      expandedChats: []
    };
  },
  computed: {
    user() {
      return this.$auth().user();
    }
  },
  async mounted() {
    if (!this.user && this.token) {
      await this.login();
    }
    if (!this.user && this.allowTestLogin) {
      await this.testLogin();
    }
  },
  methods: {
    async testLogin() {
      const testLogin = {
        email: process.env.VUE_APP_TEST_USER,
        password: process.env.VUE_APP_TEST_PASSWORD
      };
      const response = await this.$store.dispatch("users/login", testLogin);
      await this.$store.commit("users/setTokens", response.data);
      await this.$auth().fetchUser();
    },
    async login() {
      if (this.user) {
        return;
      }
      const loginData = {
        refresh_token: this.token
      };
      try {
        const response = await this.$store.dispatch(
          "users/loginWithExternal",
          loginData
        );
        this.$store.commit("users/setTokens", response.data);
        await this.$auth().fetchUser();
      } catch (e) {
        console.error(
          "Maitreja chat: not able to search user by given token. Please make sure that token is valid."
        );
        console.log("Original error:", e, e.status);
      }
    },
    async showChat() {
      if (!this.canBeAdd()) {
        return;
      }
      this.chats.push({
        id: this.chatId()
      });
      await this.login();
      this.isExpanded = false;
    },
    canBeAdd() {
      return this.chats.length === 0 ? true : this.isExpanded;
    },
    expand(userId, chatId) {
      this.isExpanded = true;
      this.expandedChats.push({
        chatId: chatId,
        userId: userId
      });
      this.sseConnect();
    },
    getIsOnline(chatId) {
      const filtered = this.expandedChats.filter(
        chat => chat.chatId === chatId
      );
      const item = filtered.length ? filtered[0] : null;
      return item ? this.onlineUsers.includes(item.userId) : false;
    },
    close(event) {
      this.chats = this.chats.filter(item => item.id !== event);
      if (this.chats.length === 0) {
        this.websocketConnection.close();
      }
    },
    chatId() {
      return (
        "_" +
        Math.random()
          .toString(36)
          .substr(2, 9)
      );
    }
  }
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#maitrea-app-widget-container {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  button {
    outline: none;
    cursor: pointer;
    background: transparent;
    border: none;
    box-shadow: none;
  }
  ul {
    list-style-type: none;
  }
}
@import "src/assets/scss/global";
</style>
