export default {
  data() {
    return {
      messages: [],
      totalCount: 0,
      allLoaded: false,
      loading: false,
      loaded: []
    };
  },

  computed: {
    dateFormat() {
      return date => {
        const messageDate = this.$moment(date)
          .local()
          .startOf("day");
        const diff = this.$moment()
          .local()
          .startOf("day")
          .diff(messageDate, "days");
        if (diff === 0)
          return (
            "Today " +
            this.$moment(date)
              .local()
              .format("HH:mm")
          );
        if (diff === 1)
          return (
            "Yesterday " +
            this.$moment(date)
              .local()
              .format("HH:mm")
          );
        if (diff >= 2)
          return this.$moment(date)
            .local()
            .format("D MMM");
      };
    }
  },

  methods: {
    async fetchMessages() {
      try {
        const data = {
          chat_id: this.externalChatId
        };
        const resp = await this.$store.dispatch("messages/list", data);
        this.messages = resp.data.messages.reverse();
        this.totalCount = resp.data.total_count;
        this.allLoaded = resp.data.messages.length === this.totalCount;
      } catch (e) {}
    },

    parserContent(text) {
      if (text) {
        const urls = text.match(
          /(((ftp|http|www|https?):\/\/)[-\w@:%_+.~#?,&//=]+)/g
        );
        if (urls) {
          urls.forEach(function(url) {
            if (
              url.includes(".jpg") ||
              url.includes(".jpeg") ||
              url.includes(".png")
            ) {
              text = text.replace(
                url,
                '<img  class="content-image" src="' + url + '" />'
              );
            } else if (url.includes(".mp4") || url.includes(".bmp")) {
              text = text.replace(
                url,
                '<video class="content-video"  controls>' +
                  '<source src="' +
                  url +
                  '" type="video/mp4">' +
                  '<source src="' +
                  url +
                  '" type="video/mp4">' +
                  "</video>"
              );
            } else {
              const name = url.split("/")[6];
              text = text.replace(
                url,
                '<a target="_blank" class="content-link" href="' +
                  url +
                  '">' +
                  name +
                  "</a>"
              );
            }
          });
        }
        return text;
      }
    },

    chatScroll(el) {
      let scrollBottom = false;
      const currentScrollPosition = el.target.scrollTop;
      if (currentScrollPosition > this.scrollPosition) scrollBottom = true;

      this.scrollPosition = currentScrollPosition;

      if (this.messages && this.messages.length && this.$refs.content) {
        const elementOffset = this.$refs.content.firstElementChild.getBoundingClientRect()
          .y;
        const elementHeight = this.$refs.content.firstElementChild.getBoundingClientRect()
          .height;

        if (
          elementHeight - elementOffset - 100 < elementHeight &&
          !scrollBottom
        ) {
          this.runPager();
        }
      }
    },

    async runPager() {
      if (this.allLoaded || this.isLoading) {
        return;
      }
      this.loading = true;
      const oldDataReverse = [...this.messages].reverse();
      const lastMessage = oldDataReverse[oldDataReverse.length - 1];
      if (this.loaded.includes(lastMessage.message_id)) {
        return;
      }
      try {
        this.loaded.push(lastMessage.message_id);
        const response = await this.$store.dispatch("messages/list", {
          chat_id: this.externalChatId,
          limit: 15,
          show_from: lastMessage.message_id
        });
        if (response.data.messages.length) {
          response.data.messages.map(item => {
            this.messages.unshift(item);
          });
        }
        this.allLoaded = this.messages.length === this.totalCount;
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },

    scrollToBottom() {
      this.$nextTick(() => {
        if (this.$refs.content) {
          this.$refs.content.scrollTop = this.$refs.content.scrollHeight;
        }
      });
    }
  }
};
