<template>
  <div
    :style="'position: fixed; transform: translateX(' + transform + ')'"
    class="first-float-chat"
  >
    <div v-if="showUserChat" class="user-chat">
      <div class="user-info-header">
        <div class="left-side">
          <span class="user-avatar">
            <img :src="chatSettings.img_url" alt="user-avatar" />
          </span>
          <span class="username">{{ chatSettings.name }}</span>
          <span v-if="isOnline" class="is-online"></span>
          <span v-if="!isOnline" class="is-offline"></span>
        </div>
        <div class="right-side">
          <button class="close-btn" @click="closeChat"></button>
        </div>
      </div>
      <div ref="content" class="message-content" @scroll="chatScroll">
        <Loader v-if="!allLoaded && messages.length > 6" />
        <div class="message-lists">
          <MessageItem
            v-for="(item, index) in messages"
            :key="`${item.message_id}_${index}`"
            :user-type="item.is_mine ? 'me' : 'user'"
            :order="item.is_mine ? 'right' : 'left'"
          >
            <span slot="avatar" class="svg">
              <img :src="item.author.photo" alt="icon" />
            </span>
            <div
              slot="message"
              class="content-item"
              v-html="parserContent(item.content)"
            ></div>
            <span slot="date" class="date">{{ dateFormat(item.created) }}</span>
            <span v-if="item.is_mine" slot="checkers" class="checkers"></span>
          </MessageItem>
        </div>
      </div>
      <MessageInput @sendMessage="sendMessage" />
    </div>
    <div v-else class="content">
      <div class="header">
        <div class="name">Nová zpráva</div>
        <button class="close-btn" @click="$emit('close', chatId)"></button>
      </div>
      <div class="search">
        <div class="label">
          <span>Komu:</span>
        </div>
        <div class="input-block">
          <input
            v-model="username"
            class="focus-visible"
            type="text"
            @focus="showUserList"
          />
        </div>
      </div>
      <UserList
        :username="username"
        :float="true"
        :show.sync="show"
        @open-chat="openUserChat"
      />
    </div>
  </div>
</template>

<script>
import UserList from "@/components/search-users/UserList";
import MessageItem from "@/components/message/MessageItem";
import MessageInput from "../message/MessageInput";
import Loader from "../Loader";
import websockets from "@/mixins/websockets";
import message from "@/mixins/message";
import EventBus from "../../plugins/event-bus";
export default {
  name: "Chat",
  components: {
    UserList,
    MessageItem,
    MessageInput,
    Loader
  },
  mixins: [websockets, message],
  props: {
    chatId: {
      required: true,
      type: String
    },
    position: {
      required: true,
      type: Number
    },
    isExpanded: {
      required: true,
      type: Boolean,
      default: false
    },
    isOnline: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      username: "",
      message: "",
      userInfo: [],
      showUserChat: false,
      show: false,
      userAvatar: "/../../img/user-avatar.svg",
      chatData: null,
      chatSettings: null
    };
  },
  computed: {
    transform() {
      return `calc(-122px + -${this.position * 108}%)`;
    },
    externalChatId() {
      return this.chatSettings ? this.chatSettings.chat_id : null;
    }
  },

  watch: {
    async externalChatId() {
      await this.fetchMessages();
      await this.scrollToBottom();
    }
  },
  mounted() {
    EventBus.$on("update-messages", () => {
      this.fetchMessages();
    });
  },
  methods: {
    showUserList() {
      this.show = this.username.length > 0;
    },
    async openUserChat(id) {
      this.chatData = await this.getOrCreateChat(id);
      this.chatSettings = await this.getChatInfo(this.chatData.chat_id);
      this.showUserChat = !!this.chatSettings.chat_id;
      this.$emit("expand", id, this.chatId);
    },
    async getOrCreateChat(opponent_id) {
      const data = {
        opponent_id
      };
      const response = await this.$store.dispatch("chats/createSingle", data);
      return response.data;
    },
    async getChatInfo(chatId) {
      const response = await this.$store.dispatch("chats/chatMembers", {
        chat_id: chatId
      });
      return response.data;
    },
    closeChat() {
      this.userInfo = this.userInfo.filter(item => item.chatId !== this.chatId);
      this.$emit("close", this.chatId);
    }
  }
};
</script>

<style lang="scss" scoped>
#maitrea-app-widget-container {
  .first-float-chat {
    filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.25));
    border-radius: 12px 12px 0 0;
    background: url("/../../img/backgroundfloat.svg");
    background-size: cover;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 310px;
    height: 493px;

    .banner-image {
      position: absolute;
    }

    .content {
      position: relative;
      padding: 20px 16px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 19px;

        .name {
          font-weight: 500;
          font-size: 17px;
          line-height: 17px;
        }

        .close-btn {
          background: transparent url("/../../img/close-chat.svg");
          border: none;
          padding: 0;
          width: 24px;
          height: 24px;
        }
      }

      .search {
        display: flex;
        align-items: center;

        .label {
          font-weight: normal;
          font-size: 17px;
          line-height: 17px;
        }
        .input-block {
          margin-left: 11px;
          width: 100%;

          input {
            width: 94%;
            border: none;
            background: transparent;
            height: 25px;
            font-size: 1rem;

            &:focus-visible {
              outline: none;
              border: none;
            }
          }
        }
      }
    }

    .user-chat {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .user-info-header {
        background: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 9px;

        .close-btn {
          background: transparent url("/../../img/close-chat.svg");
          border: none;
          padding: 0;
          width: 24px;
          height: 24px;
        }

        .left-side {
          display: flex;
          align-items: center;

          .user-avatar {
            width: 42px;
            height: 42px;
            margin-right: 9px;
            border-radius: 100%;
            overflow: hidden;
            flex: 0 0 auto;

            img {
              width: 42px;
              height: 42px;
              object-fit: cover;
            }
          }
          .username {
            font-weight: 600;
            font-size: 19px;
            line-height: 19px;
            color: #162521;
            margin-right: 9px;
          }
          .is-online {
            width: 12px;
            height: 12px;
            background: #2ad062;
            border-radius: 50%;
          }
        }
      }

      .message-content {
        overflow-y: scroll;
        height: 100vh;

        &::-webkit-scrollbar {
          width: 4px;
          border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #ddd;
          border-radius: 8px;
        }
      }

      .message-lists {
        padding: 16px;
      }
    }
  }
}
</style>
