<template>
  <div class="message-input-wrapper">
    <div v-if="loading" class="loader"></div>
    <div class="media-list-handler">
      <MessageMedia
        v-if="mediaList.length"
        :media-list="mediaList"
        @delete="removeMedia"
      ></MessageMedia>
      <MessageFiles
        v-if="filesList.length"
        :files-list="filesList"
        @delete="removeFile"
      ></MessageFiles>
    </div>

    <div class="message-block">
      <label class="open-files-btn">
        <input
          type="file"
          name="message-file"
          :accept="acceptFiles"
          multiple
          @input="onDocumentFileInputChange"
        />
      </label>
      <label class="open-media-btn">
        <input
          type="file"
          name="message-image"
          :accept="acceptVideoOrImage"
          multiple
          @input="onImageFileInputChange"
        />
      </label>
      <div class="input-area">
        <input v-model="message" type="text" @keyup="sendMessage" />
      </div>
    </div>
  </div>
</template>

<script>
import chat from "@/mixins/chat";
import MessageFiles from "@/components/message/MessageFiles";
import MessageMedia from "@/components/message/MessageMedia";
export default {
  name: "MessageInput",
  components: { MessageFiles, MessageMedia },
  mixins: [chat]
};
</script>

<style lang="scss" scoped>
.message-input-wrapper {
  position: relative;
  .loader {
    position: absolute;
    background: rgba(255, 255, 255, 0.5) url("/../../img/loader.svg") 50% 50%
      no-repeat;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: 50px 50px;
  }
  //Styles for files list
  .media-list-handler {
    max-height: 75px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 8px;
    }
  }
  //height: 83px;
  background: white;
  //position: fixed;
  //bottom: 0;
  padding: 12px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 12px;
  }

  .message-block {
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      justify-content: space-between;
    }

    input[type="file"] {
      display: none;
    }

    .open-files-btn {
      margin-right: 18px;
      cursor: pointer;
      display: flex;
      width: 24px;
      height: 24px;
      background: transparent url("/../../img/plus.svg") 50% 50% no-repeat;
      @media (max-width: 767px) {
        order: 1;
      }
    }
    .open-media-btn {
      display: flex;
      margin-right: 18px;
      cursor: pointer;
      width: 24px;
      height: 24px;
      background: transparent url("/../../img/camera.svg") 50% 50% no-repeat;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        order: 3;
        margin-left: 28px;
        margin-right: 50px;
      }
    }
    .input-area {
      width: 100%;

      @media (max-width: 767px) {
        order: 2;
        //width: auto;
      }

      input {
        width: 83%;
        background: #f3f3f3;
        border-radius: 30px;
        appearance: none;
        border: none;
        box-shadow: none;
        height: 42px;
        padding-left: 16px;
        padding-right: 16px;
        font-size: 16px;
        line-height: 16px;

        @media (max-width: 450px) {
          width: 87%;
        }

        &:focus-visible {
          outline: none;
          border: none;
        }
      }
    }
  }
}
</style>
